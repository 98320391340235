import 'react-multi-carousel/lib/styles.css';
import React from "react";

export const Skills = () => {
  return (
    <section className="about" id="about">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="about-bx wow zoomIn">
              <h2>About</h2>
              <p>Ready to elevate your vehicle servicing experience? The Rovirs app will soon be available, allowing you to experience a new way to manage your vehicles with ease.<br></br>
                Whether you're a seasoned car enthusiast or a casual driver, Rovirs is here to simplify the way you interact with your vehicles.<br></br>
                Join us on this journey to effortless vehicle management, and be among the first to experience the future of automotive servicing with Rovirs.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

