import { useState, useEffect } from "react";
import { Navbar, Nav, Container } from "react-bootstrap";
import logo from '../assets/img/logo.png';
import WhatsAppLogo from "../assets/img/whatsapp.svg";
import phonecall from "../assets/img/phone.svg";
import navIcon1 from '../assets/img/nav-icon1.svg';
import navIcon2 from '../assets/img/nav-icon2.svg';
import navIcon3 from '../assets/img/nav-icon3.svg';
import { HashLink } from 'react-router-hash-link';
import {
  BrowserRouter as Router
} from "react-router-dom";
import React from "react";
export const NavBar = () => {

  const [activeLink, setActiveLink] = useState('home');

  const onUpdateActiveLink = (value) => {
    setActiveLink(value);
  }

  const handleWhatsAppClick = () => {
    let text = `Hello,%0A%0A
        I'm reaching out for some assistance with my vehicle.%0A
        .%0A%0A
        I look forward to your support to resolve these issues.`;

    let whatsappphonenumber = '+918010323579';
    const link = `https://wa.me/${whatsappphonenumber}?text=${text}`;

    // Open WhatsApp link
    window.open(link, "_blank");
  };

  const handleCallClick = () => {
    const phoneNumber = '+918010323579'; // Phone number you want to call
    window.location.href = `tel:${phoneNumber}`; // Opens the dialer with the phone number
  };

  return (
    <Router>
      <Navbar expand="md" className={"scrolled"}>
        <Container>
          <Navbar.Toggle aria-controls="basic-navbar-nav" style={{
            padding: "10px 0px 10px 0px"
          }}>
            <span className="navbar-toggler-icon"></span>
          </Navbar.Toggle>
          <Navbar.Collapse id="basic-navbar-nav" style={{
            // padding: "25px 0px"
          }}>
            <Nav className="ms-auto">
              <Nav.Link href="#home" style={{
                padding: "10px"
              }} className={activeLink === 'home' ? 'active navbar-link' : 'navbar-link'} onClick={() => onUpdateActiveLink('home')}>Home</Nav.Link>
              <Nav.Link style={{
                padding: "10px"
              }} href="#experience" className={activeLink === 'experience' ? 'active navbar-link' : 'navbar-link'} onClick={() => onUpdateActiveLink('experience')}>Experiance</Nav.Link>
              <Nav.Link style={{
                padding: "10px"
              }} href="#about" className={activeLink === 'about' ? 'active navbar-link' : 'navbar-link'} onClick={() => onUpdateActiveLink('about')}>About</Nav.Link>

            </Nav>
            <span className="navbar-text" style={{
              marginLeft: "5px",
            }}>
              <div className="social-icon">
                <a href="https://www.linkedin.com/in/rovirs-com-82767a30a?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app"><img src={navIcon1} alt="LinkedIn" /></a>
                <a href="https://www.facebook.com/share/MYgbi1bD7z7fQnR6/?mibextid=qi2Omg"><img src={navIcon2} alt="Facebook" /></a>
                <a href="https://www.instagram.com/rovirs.mechanic?igsh=MWx6cDNnb2Y2Ympmcg=="><img src={navIcon3} alt="Instagram" /></a>
                <a href="#"><img src={WhatsAppLogo} alt="WhatsApp"
                  onClick={handleWhatsAppClick}
                  style={{
                    height: "20px",
                    width: "20px",
                    filter: "invert(100%)",  // White tint
                    transition: "filter 0.3s ease" // Smooth transition effect
                  }}
                  onMouseEnter={(e) => e.currentTarget.style.filter = "invert(0%)"} // Turn black on hover
                  onMouseLeave={(e) => e.currentTarget.style.filter = "invert(100%)"} // Back to white
                /></a>
                <a href="#"><img src={phonecall} alt="Phone"
                  onClick={handleCallClick}
                  style={{
                    height: "20px",
                    width: "20px",
                    filter: "invert(100%)",  // White tint
                    transition: "filter 0.3s ease" // Smooth transition effect
                  }}
                  onMouseEnter={(e) => e.currentTarget.style.filter = "invert(0%)"} // Turn black on hover
                  onMouseLeave={(e) => e.currentTarget.style.filter = "invert(100%)"} /></a>
              </div>
            </span>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </Router>
  )
}
