import { Container, Row, Col } from "react-bootstrap";
import contactImg from "../assets/img/mechanics.png";
import 'animate.css';
import TrackVisibility from 'react-on-screen';
import React from "react";
export const Banner = () => {

  return (
    <section className="banner" id="home">
      <Container>
        <div>
          <Row>
          {/* <span className="navbar-text">
              <HashLink to='#connect'>
                <button className="vvd"><span>Let’s Connect</span></button>
              </HashLink>
            </span> */}
          </Row>
          <Row className="aligh-items-center">
            <Col xs={12} md={6} xl={7}>
              <TrackVisibility>
                {({ isVisible }) =>
                  <div className={""}>
                    <h1>{`Elevating Your Vehicle Servicing Experiance`}</h1>
                    <p>Welcome to Rovirs, where we're dedicated to revolutionizing the way you manage your vehicle servicing needs. Our mission is simple yet ambitious: to provide you with a seamless and hassle-free experience when it comes to maintaining and servicing your vehicles. Gone are the days of juggling between multiple platforms and struggling to find answers to your vehicle-related queries. With Rovirs, everything you need is conveniently accessible in one place.</p>
                  </div>}
              </TrackVisibility>
            </Col>
            <Col xs={12} md={6} xl={5}>
              <TrackVisibility>
                {({ isVisible }) =>
                  <div className={""}>
                    <img src={contactImg} alt="Header Img" />
                  </div>}
              </TrackVisibility>
            </Col>
          </Row>
        </div>
      </Container>
    </section>
  )

}
