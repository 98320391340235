import React, {useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import headerImg from "../assets/img/image.jpg";
import 'animate.css';
import TrackVisibility from 'react-on-screen';

export const Contact = () => {

  useEffect(() => {
    // Google Analytics setup
    window.dataLayer = window.dataLayer || [];
    function gtag() {
      window.dataLayer.push(arguments);
    }
    gtag('js', new Date());
    gtag('config', 'G-V3XR5B7P6N');

    // Create and append the script element for gtag.js
    const script = document.createElement('script');
    script.async = true;
    script.src = 'https://www.googletagmanager.com/gtag/js?id=G-V3XR5B7P6N';
    document.head.appendChild(script);
  }, []);
  
  const formInitialDetails = {
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    message: ''
  }
  const [formDetails, setFormDetails] = useState(formInitialDetails);
  const [buttonText, setButtonText] = useState('Send');
  const [status, setStatus] = useState({});

  const onFormUpdate = (category, value) => {
    setFormDetails({
      ...formDetails,
      [category]: value
    })
  }
  const [errors, setErrors] = useState({});

  const validateForm = () => {
    let formErrors = {};
    formErrors.firstName = !formDetails.firstName ? "First name is required" : "";
    formErrors.lastName = !formDetails.lastName ? "Last name is required" : "";
    if (!formDetails.phone) {
      formErrors.phone = "Phone number is required.";
    } else if (!/^\d{10}$/.test(formDetails.phone)) {
      formErrors.phone = "Phone number is invalid. It should contain exactly 10 digits.";
    }

    formErrors.message = !formDetails.message ? "Message is required" : "";
    setErrors(formErrors);

    // If all values are truthy, the form is valid
    return formDetails.firstName && formDetails.lastName && formDetails.phone && formDetails.message;
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    setButtonText("Sending...");
    if (!validateForm()) {
      setButtonText("Send");
      return;
    }
    let text = `Hello,%0AMy name is ${formDetails.firstName} ${formDetails.lastName}.%0AI'm reaching out for some assistance with my vehicle.%0AHere are the details about the issues I'm facing with my vehicle: %0A${formDetails.message}.%0A%0AI look forward to your support to resolve these issues.%0A%0AThank you,%0A${formDetails.firstName} ${formDetails.lastName},%0A${formDetails.phone}.`;
    let whatsappphonenumber = '+918010323579';
    const link = `https://wa.me/${whatsappphonenumber}?text=${text}`;
    window.open(link, '_blank');
    setButtonText("Send");
  };

  return (
    <section className="contact" id="connect">
      <Container>
        <Row className="align-items-center">
          <Col size={12} md={6}>
            <TrackVisibility>
              {() =>
                <img
                  className="circular-image"
                  src={headerImg}
                  alt="Contact Us"
                  style={{
                    width: "100%",
                    marginBottom: "10px",
                  }}
                />
              }
            </TrackVisibility>
          </Col>
          <Col size={12} md={6}>
            <TrackVisibility>
              {() =>
                <div className={""}>
                  <form onSubmit={handleSubmit}>
                    <Row>
                      <Col size={12} sm={6} className="px-1">
                        <input type="text" value={formDetails.firstName} placeholder="First Name" onChange={(e) => onFormUpdate('firstName', e.target.value)} />
                        {errors.firstName && <p>{errors.firstName}</p>}
                      </Col>
                      <Col size={12} sm={6} className="px-1">
                        <input type="text" value={formDetails.lasttName} placeholder="Last Name" onChange={(e) => onFormUpdate('lastName', e.target.value)} />
                        {errors.lastName && <p>{errors.lastName}</p>}
                      </Col>
                    </Row>
                    <Row>
                      <Col size={12} className="px-1">
                        <input type="tel" value={formDetails.phone} placeholder="Phone No." onChange={(e) => onFormUpdate('phone', e.target.value)} />
                        {errors.phone && <p>{errors.phone}</p>}
                      </Col>
                    </Row>
                    <Row>
                      <Col size={12} className="px-1">
                        <textarea rows="6" value={formDetails.message} placeholder="Message" onChange={(e) => onFormUpdate('message', e.target.value)}></textarea>
                        {errors.message && <p>{errors.message}</p>}
                        <button type="submit"><span>{buttonText}</span></button>
                      </Col>
                    </Row>
                  </form>
                </div>}
            </TrackVisibility>
          </Col>
        </Row>
      </Container>
    </section>
  )
}
