
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { NavBar } from "./components/NavBar";
import { Banner } from "./components/Banner";
import { Skills } from "./components/Skills";
import { Projects } from "./components/Projects";
import { Contact } from "./components/Contact";
import { Footer } from "./components/Footer";
import React, { useEffect } from 'react';
import WhatsAppLogo from "./assets/img/whatsapp.svg";
import phonecall from "./assets/img/phone.svg";
import BikeServiceGrid from './components/BikeServiceGrid';

function App() {

  useEffect(() => {
    // Google Analytics setup
    window.dataLayer = window.dataLayer || [];
    function gtag() {
      window.dataLayer.push(arguments);
    }
    gtag('js', new Date());
    gtag('config', 'G-V3XR5B7P6N');

    // Create and append the script element for gtag.js
    const script = document.createElement('script');
    script.async = true;
    script.src = 'https://www.googletagmanager.com/gtag/js?id=G-V3XR5B7P6N';
    document.head.appendChild(script);
  }, []);

  const handleClick = () => {
    let text = `Hello,%0A%0A
        I'm reaching out for some assistance with my vehicle.%0A
        .%0A%0A
        I look forward to your support to resolve these issues.`;

    let whatsappphonenumber = '+918010323579';
    const link = `https://wa.me/${whatsappphonenumber}?text=${text}`;
    // Open WhatsApp link
    window.open(link, "_blank");
  };

  return (
    <div className="App" style={{
      position: "relative"
    }}>
      <div style={{
        position: 'fixed',
        bottom: '20px',
        right: '20px',
        backgroundColor: '#25D366',
        borderRadius: '50%',
        width: '60px',
        height: '60px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        boxShadow: '0 4px 8px rgba(0,0,0,0.2)',
        cursor: 'pointer',
        zIndex: "100"
      }} >
        {/* icon here */}
        <div>   <img
          src={WhatsAppLogo}
          alt="WhatsApp"
          onClick={handleClick}
          style={{ height: 35, width: 50, filter: "invert(100%) brightness(200%)" }}
        /></div>
      </div>
      <NavBar />
      <Contact />
      {/* <BikeServiceGrid/> */}
      <Banner />
      <Projects />
      <Skills />
    </div>

  );


}



export default App;
