import { Container, Row, Col, Tab, Nav } from "react-bootstrap";
import 'animate.css';
import TrackVisibility from 'react-on-screen';
import React from "react";
export const Projects = () => {

  return (
    <section className="project" id="experience">
      <Container>
        <Row>
          <Col size={12}>
            <TrackVisibility>
              {({ isVisible }) =>
                <div className={""}>
                  <div className="experiance-header">
                    <h2>Experience</h2>
                    <p>Our app is designed with you in mind, prioritizing three key principles: convenience, efficiency, and reliability.</p>
                  </div>
                  <div className="convenience">
                    <h3>Convenience</h3>
                    <p>Say goodbye to the days of scrambling through paperwork or navigating multiple websites to find the information you need. With Rovirs, everything you need is centralized within our user-friendly app. From tracking your vehicle's service history to scheduling appointments with certified mechanics, managing your vehicle has never been easier.</p>
                  </div>
                  <div className="efficiency">
                    <h3>Efficiency</h3>
                    <p>Time is precious, and we understand that every minute spent on vehicle maintenance is a minute taken away from your busy schedule. That's why Rovirs streamlines the servicing process, allowing you to complete tasks quickly and efficiently. Whether you're booking a routine oil change or addressing a more complex issue, our app guides you through each step with ease.</p>
                  </div>
                  <div className="reliability">
                    <h3>Reliability</h3>
                    <p>When it comes to your vehicle, trust is paramount. With Rovirs, you can rest assured knowing that your vehicle-related queries and issues are in good hands. Our platform is backed by a team of automotive experts who are dedicated to providing you with accurate information and timely assistance. Whether you're seeking advice on maintenance best practices or troubleshooting a problem, our knowledgeable team is here to help.</p>
                  </div>
                </div>}
            </TrackVisibility>
          </Col>
        </Row>
      </Container>
    </section>
  )
}
